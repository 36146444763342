<template>
  <div />
</template>

<script>
export default {
  mounted() {
    // this.$zendesk.show()
  },
}
</script>

<style>
#webWidget { height: 100px important; }
</style>
